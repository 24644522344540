import React from 'react';

const IconLoader = () => (
  <svg  id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 4667 1042">
    <g transform="translate(0.000000,1042.000000) scale(0.100000,-0.100000)" fill="currentColor">
      <path d="M603 10400 c-141 -37 -267 -113 -371 -225 -117 -125 -183 -273 -202
      -452 -7 -67 -10 -1243 -8 -3693 3 -3452 4 -3598 22 -3660 27 -96 98 -237 155
      -307 97 -118 250 -217 401 -260 62 -17 215 -18 3755 -18 l3690 0 80 27 c134
      46 220 101 320 202 75 75 99 107 138 186 91 183 82 -251 82 3910 l0 3685 -33
      95 c-90 259 -289 445 -546 510 -78 20 -112 20 -3745 19 -3565 0 -3668 -1
      -3738 -19z m3909 -1554 l-57 -530 -1117 -494 c-615 -272 -1118 -498 -1118
      -501 0 -3 503 -229 1118 -502 l1117 -495 57 -530 c32 -291 56 -545 55 -565
      l-2 -36 -1870 873 -1870 873 0 381 0 381 1870 873 1870 873 2 -36 c2 -20 -23
      -274 -55 -565z"/>
      <path d="M11825 10393 c-172 -14 -303 -42 -380 -80 -64 -32 -93 -58 -116 -108
      -18 -39 -19 -175 -19 -4138 0 -3676 2 -4101 15 -4135 40 -95 143 -142 375
      -173 125 -17 662 -17 790 -1 214 28 326 75 373 157 9 15 13 400 17 1499 l5
      1478 938 -1484 c521 -825 954 -1500 975 -1520 84 -83 232 -121 532 -139 360
      -21 794 2 954 51 188 58 214 195 87 447 -27 54 -472 753 -990 1552 -927 1433
      -941 1455 -923 1475 397 446 1598 1816 1632 1861 88 117 150 266 150 365 0 59
      -35 128 -78 157 -91 60 -242 90 -533 103 -232 10 -568 -1 -699 -24 -188 -33
      -269 -72 -358 -172 -29 -32 -421 -554 -870 -1159 l-817 -1100 -5 2440 c-5
      2280 -6 2442 -22 2471 -71 130 -313 186 -783 182 -115 -1 -228 -3 -250 -5z"/>
      <path d="M22813 10389 c-168 -16 -302 -51 -380 -99 -18 -10 -45 -38 -60 -61
      l-28 -43 0 -4120 c0 -3937 1 -4122 18 -4154 58 -107 245 -156 638 -168 236 -8
      510 7 645 35 98 20 163 48 207 90 61 56 57 -87 57 1938 l1 1858 92 110 c111
      134 325 349 427 432 86 69 221 158 298 197 332 167 742 122 989 -108 194 -180
      301 -452 333 -841 6 -72 10 -788 10 -1800 0 -1067 4 -1693 10 -1714 22 -76
      110 -132 255 -162 134 -28 409 -43 645 -35 400 13 582 61 634 170 16 33 16
      165 13 1983 -4 2132 0 2005 -63 2353 -90 497 -302 906 -609 1173 -225 195
      -485 324 -785 390 -192 42 -290 52 -545 52 -255 -1 -333 -10 -534 -61 -362
      -94 -750 -321 -1083 -634 l-88 -82 0 1541 c0 1270 -2 1547 -13 1574 -58 138
      -304 199 -792 196 -115 -1 -247 -5 -292 -10z"/>
      <path d="M29730 10184 c-19 -2 -69 -9 -110 -15 -326 -47 -485 -178 -556 -457
      -48 -189 -50 -506 -4 -698 61 -258 191 -376 480 -435 139 -29 491 -37 658 -15
      220 29 378 96 466 197 57 65 111 188 137 309 32 150 33 467 1 614 -23 110 -75
      229 -127 292 -75 91 -210 155 -400 190 -86 15 -459 28 -545 18z"/>
      <path d="M37130 10184 c-78 -8 -213 -34 -279 -55 -183 -58 -291 -161 -352
      -336 -39 -114 -50 -186 -56 -378 -8 -267 13 -406 81 -550 106 -225 338 -315
      808 -315 615 0 840 157 897 625 24 195 9 429 -36 585 -69 240 -206 351 -500
      405 -86 16 -460 28 -563 19z"/>
      <path d="M32980 9210 c-198 -18 -338 -57 -399 -113 -13 -12 -32 -40 -42 -62
      -17 -38 -19 -79 -19 -672 l0 -632 -358 -3 -359 -3 -40 -27 c-50 -33 -95 -119
      -120 -228 -26 -112 -26 -620 0 -737 22 -103 46 -160 87 -207 59 -67 52 -66
      440 -66 l350 0 0 -1492 c0 -937 4 -1545 10 -1633 34 -451 133 -786 311 -1052
      67 -101 242 -275 343 -343 187 -125 426 -211 706 -255 141 -23 559 -31 728
      -16 317 30 586 102 721 192 95 64 143 155 174 333 19 116 16 580 -6 691 -23
      121 -43 170 -78 200 -45 37 -96 33 -236 -19 -195 -72 -284 -90 -458 -90 -112
      -1 -166 4 -215 17 -242 65 -355 218 -417 562 -14 79 -17 255 -20 1498 l-4
      1407 1235 0 1236 0 2 -2257 c3 -2146 4 -2260 21 -2291 35 -65 138 -116 282
      -141 228 -40 763 -38 978 3 161 31 253 88 276 171 15 54 16 5351 1 5443 -29
      177 -107 271 -265 321 -45 14 -252 16 -1907 18 l-1858 3 0 638 0 637 -23 40
      c-44 78 -137 122 -317 151 -115 18 -604 27 -760 14z"/>
      <path d="M18985 7864 c-604 -48 -1097 -232 -1451 -542 -252 -221 -430 -526
      -503 -863 -85 -396 -35 -843 132 -1175 115 -229 325 -459 567 -621 218 -146
      521 -287 1024 -478 490 -186 681 -296 807 -462 70 -92 94 -172 93 -308 -2
      -245 -109 -414 -327 -520 -208 -101 -487 -137 -808 -105 -313 32 -696 165
      -1085 378 -212 115 -262 130 -338 98 -45 -19 -73 -58 -96 -135 -51 -172 -54
      -637 -5 -826 44 -169 110 -244 303 -343 274 -140 622 -241 1032 -299 178 -25
      747 -25 935 0 621 84 1071 277 1408 606 261 255 413 546 483 926 27 145 27
      558 0 700 -54 288 -196 568 -394 777 -258 271 -586 456 -1247 703 -369 137
      -414 155 -541 215 -355 168 -504 340 -504 584 0 154 48 274 150 376 169 170
      467 238 875 200 154 -15 280 -42 459 -100 194 -63 308 -110 512 -212 183 -91
      220 -100 287 -70 53 23 77 62 98 157 19 82 19 629 1 735 -19 107 -61 190 -123
      239 -189 150 -609 286 -1070 347 -119 15 -558 27 -674 18z"/>
      <path d="M29710 7759 c-286 -14 -479 -72 -538 -161 l-27 -42 -3 -2785 c-2
      -1958 0 -2797 8 -2823 30 -103 138 -157 380 -189 125 -17 662 -17 790 -1 229
      30 345 83 379 175 8 20 11 872 11 2820 0 2699 -1 2793 -19 2827 -39 75 -139
      124 -311 155 -78 14 -397 36 -480 33 -19 0 -105 -5 -190 -9z"/>
      <path d="M39605 7718 c-80 -28 -132 -100 -160 -220 -24 -105 -38 -322 -31
      -483 10 -239 50 -392 125 -477 64 -73 -12 -68 1090 -68 545 0 991 -2 991 -4 0
      -2 -478 -741 -1062 -1642 -583 -902 -1081 -1673 -1105 -1714 -48 -83 -84 -173
      -109 -270 -21 -84 -32 -617 -15 -728 24 -155 89 -256 194 -303 l52 -24 1865
      -3 c1393 -2 1876 0 1907 9 89 24 140 112 174 299 22 120 19 571 -4 680 -30
      140 -69 208 -142 248 -27 16 -131 17 -1146 22 l-1117 5 810 1255 c1243 1928
      1282 1989 1332 2081 104 193 127 307 133 670 9 456 -23 581 -166 648 l-56 26
      -1765 2 c-1219 1 -1774 -2 -1795 -9z"/>
      <path d="M45542 7610 c-297 -42 -476 -168 -558 -394 -45 -125 -58 -240 -58
      -526 -1 -279 9 -388 46 -510 70 -229 224 -358 491 -410 146 -29 508 -29 654 0
      335 66 495 254 542 635 16 130 14 433 -4 568 -38 288 -142 460 -336 556 -134
      66 -225 82 -489 86 -124 2 -253 0 -288 -5z"/>
      <path d="M45627 3569 c-156 -10 -256 -32 -344 -73 -93 -44 -155 -106 -198
      -199 -61 -128 -65 -181 -65 -798 l0 -549 -372 -862 c-419 -975 -411 -950 -344
      -1013 66 -62 180 -79 494 -73 331 7 443 39 559 162 74 77 913 1292 1009 1460
      175 305 263 596 294 972 17 191 8 528 -15 609 -54 191 -165 295 -362 339 -142
      32 -409 42 -656 25z"/>
    </g>
  </svg>
);

export default IconLoader;
